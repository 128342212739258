import { useEffect, useState } from "react"
import timeAgo from "utils/timeAgo"
import useT from "utils/useT"
import { motion } from "framer-motion"
import { ReactionsMap, submitter, translatedDescription } from "types/types"
import useGlobalStore from "stores/global"
import useRouterStore from "stores/routerStore"
import imagePlaceholder from "utils/imagePlaceholder"
import Link from "next/link"
import { FallbackImage } from "components/FallbackImage"
import TruncatedText from "components/TruncateText"
import { event } from "utils/gtag"
import { Emoji } from "emoji-picker-react"

const CompactReactionsDisplay = ({
  reactions,
}: {
  reactions?: ReactionsMap
}) => {
  // Get total reactions count
  const totalReactions = Object.values(reactions || {}).reduce(
    (sum, users) => sum + Object.keys(users).length,
    0,
  )

  // Get unique emojis, limit to first 3 for display
  const emojiKeys = Object.keys(reactions || {})

  if (!totalReactions) return null

  return (
    <div className="d-flex align-items-center m-3 mt-n1">
      <div className="emoji-avatar-group me-2">
        {emojiKeys.map((emoji, index) => (
          <div
            key={emoji}
            className="emoji-avatar"
            style={{
              zIndex: 3 - index,
              marginLeft: index > 0 ? "-8px" : "0",
            }}
          >
            <Emoji unified={emoji} size={20} />
          </div>
        ))}
      </div>
      {totalReactions > 0 && (
        <span className="font-11 opacity-70">{totalReactions}</span>
      )}
    </div>
  )
}

interface Props {
  id?: string
  uid?: string
  displayName?: string
  photoURL?: string
  lastModified?: string
  submitterDescription?: string
  translatedDescription?: translatedDescription[]
  handleClick: () => void
  media?: submitter["other"]["tweet"]["media"]
  trackId?: number
  reactions?: submitter["reactions"]
}

const SubmitterCard = ({
  id,
  uid,
  displayName,
  photoURL,
  lastModified,
  submitterDescription,
  translatedDescription,
  handleClick,
  media,
  trackId,
  reactions,
}: Props) => {
  const t = useT()
  const isDark = useGlobalStore((state) => state.isDark)

  const translateSubmitterNote = useGlobalStore(
    (state) => state.translateSubmitterNote,
  )
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const locale = useRouterStore((state) => state.router?.locale)

  const defaultTranslate =
    translatedDescription &&
    translatedDescription?.find((item) => locale?.includes(item.to))
  const [translate, setTranslate] = useState(true)
  const [description, setDescription] = useState(
    defaultTranslate && translateSubmitterNote
      ? defaultTranslate?.text
      : submitterDescription,
  )
  useEffect(() => {
    setDescription(
      defaultTranslate && translateSubmitterNote
        ? defaultTranslate?.text
        : submitterDescription,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitterDescription])

  const formattedDate = lastModified && timeAgo(new Date(lastModified))

  const changeDescription = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation()
    translate
      ? setDescription(submitterDescription)
      : setDescription(defaultTranslate?.text)
    setTranslate(!translate)
  }
  const descriptionLang =
    translatedDescription &&
    translatedDescription?.find(
      (item) =>
        item.text.replace(/\s/g, "") ===
        submitterDescription?.replace(/\s/g, ""),
    )?.to

  const showSeeOriginalBtn =
    translatedDescription &&
    translateSubmitterNote &&
    !descriptionLang?.includes(locale || "")
  return (
    <motion.div
      layoutId={animationToggle ? `submitter-card-${uid}` : undefined}
      onClick={handleClick}
      className="card card-style submitter-card"
    >
      <div className="content">
        <div className="d-flex">
          <Link
            href={`/profile/${uid}`}
            onClick={(e) => {
              e.stopPropagation()
              event({
                action: "submitter_card_photo_click",
                category: "submitter note",
                label: "submitter note edited",
                value: displayName,
              })
            }}
          >
            <motion.div
              layoutId={animationToggle ? `submitter-photo-${uid}` : undefined}
              className="me-2"
            >
              <FallbackImage
                className="rounded-circle border border-2 border-highlight-light p-0-5"
                src={photoURL ? photoURL : "/images/avatars/4s.png"}
                width={45}
                height={45}
                alt="profile photo"
                placeholder="blur"
                blurDataURL={imagePlaceholder(isDark)}
              />
            </motion.div>
          </Link>
          <div className="align-self-center">
            <motion.h6
              layoutId={animationToggle ? `submitter-name-${uid}` : undefined}
              className="mb-2 mt-1"
            >
              {displayName ? displayName : t.previewApp.submitter.guest}
            </motion.h6>
            <motion.p
              layoutId={animationToggle ? `submitter-date-${uid}` : undefined}
              className="font-11 mb-0 mt-n2 opacity-90"
            >
              {formattedDate ? formattedDate : null}
            </motion.p>
          </div>

          <div className="flex-grow-1 align-self-center text-end">
            <motion.h6
              layoutId={
                animationToggle ? `submitter-note-header-${uid}` : undefined
              }
              className="mb-0"
            >
              <i className="fa fa-check-circle color-green-dark scale-icon me-2" />
              <span className="opacity-80">
                {t.previewApp.submitter.submitterNote}
              </span>
            </motion.h6>
          </div>
        </div>
        <motion.div
          layoutId={
            animationToggle ? `submitter-description-${uid}` : undefined
          }
          className="mt-3 my-3"
          style={{ height: 70 }}
          dir="auto"
        >
          <TruncatedText text={description} />
        </motion.div>

        {showSeeOriginalBtn ? (
          <a
            className="chip chip-small m-0"
            onClick={(e) => changeDescription(e)}
          >
            {translate ? (
              <i className="bg-blue-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 17 17"
                >
                  <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                  <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                </svg>
              </i>
            ) : (
              <i className="fa fa-check bg-green-dark"></i>
            )}
            <strong className="font-400">
              {translate
                ? t.previewApp.submitter.seeOriginal
                : t.previewApp.submitter.original}
            </strong>
          </a>
        ) : (
          <div style={{ height: 30 }} />
        )}
        {media && (
          <div
            title="view photos"
            className="m-0 ms-2 icon icon-xxs rounded-m bg-twitter color-white"
          >
            <i className="fa fa-image"></i>
          </div>
        )}
      </div>
      <CompactReactionsDisplay reactions={reactions} />
    </motion.div>
  )
}

export default SubmitterCard
