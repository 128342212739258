/* eslint-disable @next/next/no-img-element */
import { useState } from "react"
import timeAgo from "utils/timeAgo"
import useT from "utils/useT"
import { motion } from "framer-motion"
import { AppInterface, submitter, translatedDescription } from "types/types"
import useGlobalStore from "stores/global"
import useRouterStore from "stores/routerStore"
import imagePlaceholder from "utils/imagePlaceholder"
import Link from "next/link"
import useSessionStore from "stores/session"
import dynamic from "next/dynamic"
import { FallbackImage } from "components/FallbackImage"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { submitMutation } from "queries"
import useSnackbarStore from "stores/snackbar"
import { event } from "utils/gtag"
import FullCardModal from "./FullCardModal"
import Image from "next/image"
import Reactions from "views/feed/Reactions"
const DeleteComment = dynamic(() => import("./DeleteComment"))

interface Props {
  trackId?: number
  uid?: string
  displayName?: string
  photoURL?: string
  lastModified?: string
  submitterDescription?: string
  translatedDescription?: translatedDescription[]
  submittedApp?: AppInterface
  media?: submitter["other"]["tweet"]["media"]
  reactions?: submitter["reactions"]
}

const FullSubmitterCard = ({
  trackId,
  uid,
  displayName,
  photoURL,
  lastModified,
  submitterDescription,
  translatedDescription,
  submittedApp,
  media,
  reactions,
}: Props) => {
  const t = useT()
  const queryClient = useQueryClient()
  const [editMode, setEditMode] = useState(false)
  const [newDescription, setNewDescription] = useState("")
  const [expandImage, setExpandImage] = useState("")
  const isDark = useGlobalStore((state) => state.isDark)
  const toggleToast = useSnackbarStore((state) => state.toggleToast)
  const userId = useSessionStore((state) => state.user.uid)
  const isAdmin = useSessionStore((state) => state.user.isAdmin)
  const token = useSessionStore((state) => state.user.accessToken)
  const translateSubmitterNote = useGlobalStore(
    (state) => state.translateSubmitterNote,
  )
  const sameUser = uid === userId
  const animationToggle = useGlobalStore((state) => state.animationToggle)
  const locale = useRouterStore((state) => state.router?.locale)

  const defaultTranslate =
    translatedDescription &&
    translatedDescription?.find((item) => locale?.includes(item.to))
  const [translate, setTranslate] = useState(true)

  const defaultDescription =
    defaultTranslate?.text &&
    submitterDescription &&
    submitterDescription?.length / 2 > defaultTranslate?.text?.length
      ? submitterDescription
      : defaultTranslate && translateSubmitterNote
      ? defaultTranslate?.text
      : submitterDescription
  const [description, setDescription] = useState(defaultDescription)
  const formattedDate = timeAgo(new Date(lastModified || "null"))

  const changeDescription = () => {
    translate
      ? setDescription(submitterDescription)
      : setDescription(defaultTranslate?.text)
    setTranslate(!translate)
  }
  const descriptionLang =
    translatedDescription &&
    translatedDescription?.find(
      (item) =>
        item.text.replace(/\s/g, "") ===
        submitterDescription?.replace(/\s/g, ""),
    )?.to

  const { mutate, isPending: isSubmitting } = useMutation({
    mutationFn: () =>
      submitMutation({
        method: "PATCH",
        displayName,
        photoURL,
        submittedApp,
        submitterDescription: newDescription,
        uid,
        token,
        ...(media && { other: { tweet: { media } } }),
      }),
    onSettled: async (response) => {
      if (response.acknowledged) {
        await queryClient.fetchQuery({
          queryKey: ["fetchApp", String(trackId)],
        })
        setDescription(newDescription)
        toggleToast({
          text: t.submit.success,
          color: "green",
        })
        setEditMode(false)
        event({
          action: "note_edit",
          category: "submitter note",
          label: "submitter note edited",
        })
      } else {
        toggleToast({
          text: t.submit.error,
          color: "red",
        })
        event({
          action: "note_edit_failed",
          category: "submitter note",
          label: "submitter note edit failed",
        })
      }
    },
  })

  return (
    <motion.div
      onPointerDownCapture={(e) => editMode && e.stopPropagation()}
      layoutId={animationToggle ? `submitter-card-${uid}` : undefined}
      className="card card-style full-submitter-card"
    >
      <div className="content">
        <div className="d-flex">
          <Link
            href={`/profile/${uid}`}
            onClick={(e) => {
              e.stopPropagation()
              event({
                action: "submitter_card_photo_click",
                category: "submitter note",
                label: "submitter note edited",
                value: displayName,
              })
            }}
          >
            <motion.div
              layoutId={animationToggle ? `submitter-photo-${uid}` : undefined}
              className="me-2"
            >
              <FallbackImage
                className="rounded-circle border border-2 border-highlight-light p-0-5"
                src={photoURL ? photoURL : "/images/avatars/4s.png"}
                width={45}
                height={45}
                alt="profile photo"
                placeholder="blur"
                blurDataURL={imagePlaceholder(isDark)}
                unoptimized
              />
            </motion.div>
          </Link>
          <div className="align-self-center">
            <motion.h6
              layoutId={animationToggle ? `submitter-name-${uid}` : undefined}
              className="mb-2 mt-1"
            >
              {displayName ? displayName : t.previewApp.submitter.guest}
            </motion.h6>
            <motion.p
              layoutId={animationToggle ? `submitter-date-${uid}` : undefined}
              className="font-11 mb-0 mt-n2 opacity-90"
            >
              {formattedDate ? formattedDate : null}
            </motion.p>
          </div>
          <div className="flex-grow-1 align-self-center text-end">
            <motion.h6
              layoutId={
                animationToggle ? `submitter-note-header-${uid}` : undefined
              }
              className="mb-0"
            >
              <i className="fa fa-check-circle color-green-dark scale-icon me-2" />
              <span className="opacity-80">
                {t.previewApp.submitter.submitterNote}
              </span>
            </motion.h6>
          </div>
        </div>
        <motion.p
          layoutId={
            animationToggle ? `submitter-description-${uid}` : undefined
          }
          className="mt-3 full-submitter-description"
          style={{ minHeight: media ? "fit-content" : "unset" }}
          dir="auto"
          key={description}
        >
          {editMode ? (
            <textarea
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              dir="auto"
              className="w-100"
              rows={10}
            />
          ) : (
            description
          )}
        </motion.p>
        <div className="d-flex overflow-scroll gap-3 px-3 mx-n3 pb-3 align-items-center">
          {media?.map((item: any) => (
            <motion.div
              layoutId={item.media_url_https}
              key={item.media_url_https}
            >
              <Image
                src={item.media_url_https || imagePlaceholder(isDark)}
                placeholder="blur"
                blurDataURL={imagePlaceholder(isDark)}
                unoptimized
                alt={item.type}
                className="rounded-sm"
                onClick={() => setExpandImage(item.media_url_https)}
                style={{ maxHeight: 250, objectFit: "cover" }}
                width={200}
                height={200}
              />
            </motion.div>
          ))}
        </div>
        <FullCardModal
          modalOpen={!!expandImage}
          close={() => setExpandImage("")}
          disableDragUp
        >
          <div className="d-flex overflow-scroll gap-3 px-3 pb-3 align-items-center">
            {media?.map((item: any) => (
              <motion.img
                layoutId={item.media_url_https}
                key={item.media_url_https}
                src={item.media_url_https}
                alt={item.type}
                className="rounded-sm"
                onClick={() => setExpandImage(item.media_url_https)}
                style={{ maxWidth: "95%", maxHeight: "70vh" }}
              />
            ))}
          </div>
        </FullCardModal>
        <div className="d-flex gap-3">
          {translatedDescription &&
            translateSubmitterNote &&
            !descriptionLang?.includes(locale || "") && (
              <button
                className="chip chip-small m-0"
                onClick={changeDescription}
              >
                {translate ? (
                  <i className="bg-blue-dark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 17 17"
                    >
                      <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
                      <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
                    </svg>
                  </i>
                ) : (
                  <i className="fa fa-check bg-green-dark"></i>
                )}
                <strong className="font-400">
                  {translate
                    ? t.previewApp.submitter.seeOriginal
                    : t.previewApp.submitter.original}
                </strong>
              </button>
            )}
          {editMode ? (
            <div className="d-flex gap-3">
              <button
                className="icon icon-xxs rounded-m bg-danger color-white"
                onClick={() => {
                  setEditMode(false)
                }}
              >
                <i className="fa fa-times"></i>
              </button>
              <button
                disabled={newDescription.length === 0}
                className={`icon icon-xxs rounded-m color-white ${
                  isSubmitting ? "bg-yellow-dark" : "bg-green-dark"
                }`}
                onClick={() => mutate()}
              >
                <i
                  className={`fa ${
                    isSubmitting ? "fa-sync fa-spin" : "fa-check"
                  }`}
                ></i>
              </button>
            </div>
          ) : (
            (isAdmin || sameUser) && (
              <>
                <DeleteComment trackId={trackId} submitterId={uid} />
                <button
                  className="icon icon-xxs rounded-m bg-blue-dark color-white"
                  onClick={() => {
                    !newDescription && setNewDescription(submitterDescription!)
                    setEditMode(true)
                  }}
                >
                  <i className="fa fa-pen"></i>
                </button>
                {media && (
                  <a
                    title="view tweet"
                    className="icon icon-xxs rounded-m bg-twitter color-white"
                    href={media?.[0]?.expanded_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                )}
              </>
            )
          )}
        </div>
      </div>
      {trackId && uid && (
        <Reactions trackId={trackId} reactions={reactions} submitterId={uid} />
      )}
    </motion.div>
  )
}

export default FullSubmitterCard
