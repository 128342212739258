import { useEffect, useState } from "react"
import MainAppCard from "./MainAppCard/MainAppCard"
import Screenshots from "./Screenshots"
import SubmitterCard from "./SubmitterCard"
import ShareMenu from "views/AppPreview/ShareMenu"
import { event } from "utils/gtag"
import { NextSeo, SoftwareAppJsonLd } from "next-seo"
import { AppInterface, submitter } from "types/types"
import { AnimatePresence, motion } from "framer-motion"
import Modal from "components/modal"
import FullSubmitterCard from "./FullSubmitterCard"
import SubmitterCardModal from "./FullCardModal"
import DragClose from "components/DragClose"
import RenderIfVisible from "react-render-if-visible"
import useT from "utils/useT"
import useUnsavedStore from "stores/unsaved"
import useGlobalStore from "stores/global"
import useSnackbarStore from "stores/snackbar"
import { fetchApp, fetchPrice, updatePriceFn } from "queries"
import useQueryPlus from "utils/useQueryPlus"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import MobileMainAppCard from "./MainAppCard/MobileMainAppCard"
import useRouterStore from "stores/routerStore"
import AppsList from "components/AppsList"
import Link from "next/link"
import getLink from "utils/getLink"

const StandaloneAppPreview = ({
  id,
  appItem,
  relatedApps,
  isMobile,
  OGThumbnail,
  serverLocale,
}: {
  id: number
  appItem?: AppInterface
  relatedApps?: AppInterface[]
  isMobile?: boolean
  OGThumbnail?: string
  serverLocale?: string
}) => {
  const t = useT(serverLocale)
  const queryClient = useQueryClient()
  const back = useRouterStore((state) => state?.router?.back)
  const toggleSnackbar = useSnackbarStore((state) => state.toggleSnackbar)
  const toggleToast = useSnackbarStore((state) => state.toggleToast)
  const toggleShareMenu = useUnsavedStore((state) => state.toggleShareMenu)
  const [submitterCardId, setSubmitterCardId] = useState<string>()
  const openShareMenu = useUnsavedStore((state) => state.openShareMenu)
  const animationToggle = useGlobalStore((state) => state.animationToggle)

  const { data: app } = useQueryPlus<AppInterface>({
    queryFn: () => fetchApp(id),
    queryKey: ["fetchApp", String(id)],
    staleTime: 1 * 60 * 60 * 1000, // 1 hour
    gcTime: 1 * 60 * 60 * 1000, // 1 hour
    enabled: !appItem || !appItem?._id,
    initialData: appItem,
  })

  const selectedSubmitterCard = app?.submitters?.find(
    (submitter) => submitter.uid === submitterCardId,
  )

  const lastModified = app?.lastModified
  const lastPriceChecked = app?.lastPriceChecked
  const isAppRemoved = app?.appRemoved
  const priceUpdated = app?.priceUpdated
  const appIcon = app?.artworkUrl100
  const submitters = app?.submitters
  const submitterPrice = app?.submitterPrice
  const appDescription = app?.description
  const screenshotUrls = app?.screenshotUrls
  const ipadScreenshotUrls = app?.ipadScreenshotUrls
  const currentPrice = app?.price
  const appRating = app?.averageUserRating
  const appRatingCount = app?.userRatingCount
  const trackId = (app?.trackId || appItem?.trackId || id) as number
  const trackName = app?.trackName
  const lastSubmitter = app?.submitters?.[0]
  const primaryGenreName = app?.primaryGenreName
  const wrapperType = app?.wrapperType
  const currentHasInAppPurchases = app?.hasInAppPurchases
  const currentHasMonthlySubscriptions = app?.hasMonthlySubscriptions
  const PRICE_REFRESH_DELAY = 20 * 60 * 1000 // 20 min
  const DEAL_OVER_DELAY = 48 * 60 * 60 * 1000 // 48 hours
  const isUpdateDue =
    lastModified &&
    new Date(lastPriceChecked || priceUpdated || lastModified).getTime() +
      PRICE_REFRESH_DELAY <
      new Date().getTime()

  useEffect(() => {
    if (app && !app?._id) {
      event({
        action: "itunes_app_view",
        category: "itunes app view",
        label: "itunes app view, no appollow",
        value: trackName,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!app?._id])

  const isDealOver =
    priceUpdated !== undefined &&
    currentPrice !== undefined &&
    submitterPrice !== undefined &&
    currentPrice > submitterPrice &&
    new Date(priceUpdated).getTime() + DEAL_OVER_DELAY > new Date().getTime()

  useEffect(() => {
    if (isDealOver) {
      toggleToast({ color: "red", text: t.previewApp.dealOver })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDealOver])

  const { data: itunesApp } = useQueryPlus({
    queryFn: () => fetchPrice(id, appItem?.trackViewUrl || app?.trackViewUrl),
    queryKey: ["checkPrice", id],
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    gcTime: 24 * 60 * 60 * 1000, // 24 hours
    enabled: !!(
      (!!id && (appItem?.trackViewUrl || app?.trackViewUrl) && !!isUpdateDue) ||
      isAppRemoved
    ),
  })
  const itunesAppItem = itunesApp?.results?.[0]
  const noiTunesApp = itunesApp && !itunesAppItem
  const itunesPrice = itunesAppItem?.price
  const { mutate } = useMutation({
    mutationFn: updatePriceFn,
    onMutate: () => {
      toggleToast({
        text: t.snackbar.updatingPrice,
        color: "loading",
      })
    },
    onSuccess: async (response) => {
      if (response.acknowledged) {
        toggleToast({
          text: t.snackbar.priceUpdated,
          color: "green",
        })
        queryClient.fetchQuery({
          queryKey: ["fetchApp", String(id)],
        })
        queryClient.fetchQuery({
          queryKey: ["getApps"],
        })
        queryClient.fetchQuery({
          queryKey: ["getGames"],
        })
        queryClient.fetchQuery({
          queryKey: ["getAll"],
        })
        queryClient.fetchQuery({
          queryKey: ["getAll" + "discounted"],
        })
        event({
          action: "price_update",
          category: "App Price",
          label: "App Price Updated",
          value: trackName,
        })
      }
    },
    onError: (e) => {
      toggleSnackbar({
        text: t.snackbar.appUpdateError,
        color: "red",
      })
      event({
        action: "price_update_failed",
        category: "App Price",
        label: "App Price Update Failed",
        value: trackName + " - " + e,
      })
    },
  })

  useEffect(() => {
    const isFree = Number(itunesPrice) === 0
    const shouldUpdateDueToIAP =
      itunesAppItem &&
      (itunesAppItem.hasInAppPurchases !== currentHasInAppPurchases ||
        itunesAppItem.hasMonthlySubscriptions !==
          currentHasMonthlySubscriptions)

    if (
      itunesAppItem &&
      isUpdateDue &&
      (itunesPrice !== currentPrice || shouldUpdateDueToIAP)
    ) {
      mutate({
        currentPrice,
        trackId,
        trackName,
        lastSubmitter,
        itunesAppItem,
        translations: appItem?.submitters?.[0]?.translatedDescription,
        notify: itunesPrice !== currentPrice && isFree,
      })
    }
  }, [
    itunesAppItem,
    itunesPrice,
    currentPrice,
    isUpdateDue,
    trackId,
    trackName,
    lastSubmitter,
    currentHasInAppPurchases,
    currentHasMonthlySubscriptions,
  ])

  useEffect(() => {
    if (noiTunesApp && !isAppRemoved) {
      mutate({
        appRemoved: true,
        currentPrice,
        trackName,
        lastSubmitter,
        trackId,
        itunesAppItem,
      })
    }
    if (isAppRemoved && noiTunesApp === false) {
      mutate({
        currentPrice,
        trackId,
        trackName,
        lastSubmitter,
        itunesAppItem,
      })
    }
  }, [
    itunesAppItem,
    currentPrice,
    mutate,
    trackId,
    noiTunesApp,
    isAppRemoved,
    trackName,
    lastSubmitter,
  ])

  const locale = useRouterStore((state) => state.router?.locale) || serverLocale

  const defaultTranslate = submitters?.[0]?.translatedDescription?.find(
    (item) => locale?.includes(item.to),
  )
  const calcDescription = defaultTranslate
    ? defaultTranslate?.text
    : submitters?.[0]?.submitterDescription
  const images = [{ url: appIcon!, alt: trackName + " icon | Appollow" }]
  if (OGThumbnail)
    images.unshift({
      url: OGThumbnail && JSON.parse(OGThumbnail!),
      alt: trackName + " thumbnail | Appollow",
    })

  return (
    <AnimatePresence>
      <NextSeo
        key={`${id}-seo`}
        title={trackName || "Loading" + " | Appollow"}
        facebook={{ appId: "381042603551124" }}
        description={`${calcDescription ? calcDescription : appDescription}`}
        canonical={`https://www.appollow.net/${
          serverLocale === "en" ? "" : serverLocale
        }${getLink(trackId, trackName)}`}
        languageAlternates={[
          {
            hrefLang: "ar",
            href: `https://www.appollow.net/ar${getLink(trackId, trackName)}`,
          },
          {
            hrefLang: "en",
            href: `https://www.appollow.net${getLink(trackId, trackName)}`,
          },
          {
            hrefLang: "tr-TR",
            href: `https://www.appollow.net/tr-TR${getLink(
              trackId,
              trackName,
            )}`,
          },
        ]}
        openGraph={{
          url: `https://www.appollow.net/${serverLocale}${getLink(
            trackId,
            trackName,
          )}`,
          title: trackName + " | Appollow",
          description: `${calcDescription ? calcDescription : appDescription}`,
          images: images,
          siteName: "Appollow",
          type: "article",
          article: {
            publishedTime: lastModified,
            modifiedTime: priceUpdated,
          },
        }}
        twitter={{
          site: "@Appollowapp",
          cardType: "summary_large_image",
        }}
      />
      <SoftwareAppJsonLd
        key={`${id}-jsonld`}
        name={trackName!}
        price={itunesPrice}
        priceCurrency="USD"
        aggregateRating={
          appRatingCount && {
            ratingValue: String(appRating),
            reviewCount: String(appRatingCount),
          }
        }
        operatingSystem="iOS"
        applicationCategory={`${primaryGenreName} ${wrapperType}`}
      />
      <DragClose
        key={`${id}-dragClose`}
        dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
        callbackFn={back}
      >
        {isMobile ? <MobileMainAppCard app={app} /> : <MainAppCard app={app} />}
      </DragClose>

      <div key="submitter-0" className="d-flex overflow-scroll">
        {submitters
          ?.sort((a: any, b: any) => {
            return (
              new Date(b?.lastModified).getTime() -
              new Date(a?.lastModified).getTime()
            )
          })
          .map((submitter: submitter, i: number) => (
            <div
              key={"submitter-" + submitter.uid} // Use uid for consistent keys
              style={{
                width: submitters?.length === 1 ? "100%" : "90%",
                flexShrink: 0,
              }}
            >
              <RenderIfVisible visibleOffset={-25} stayRendered>
                <motion.div
                  initial={
                    animationToggle && {
                      transform: "scale(0.8)",
                      opacity: 0,
                    }
                  }
                  animate={
                    animationToggle && {
                      transform: ["scale(0.8)", "scale(1.02)", "scale(1)"],
                      opacity: 1,
                    }
                  }
                  transition={
                    animationToggle
                      ? {
                          duration: 0.3,
                          type: "keyframes",
                          ease: "easeOut",
                        }
                      : undefined
                  }
                >
                  <SubmitterCard
                    uid={submitter?.uid}
                    key={submitter?.uid}
                    id={submitter?.uid} // Use uid as id for consistent layoutId
                    handleClick={() => setSubmitterCardId(submitter?.uid)}
                    displayName={submitter?.displayName}
                    photoURL={submitter?.photoURL}
                    lastModified={submitter?.lastModified}
                    submitterDescription={submitter?.submitterDescription}
                    translatedDescription={submitter?.translatedDescription}
                    media={submitter?.other?.tweet?.media}
                    trackId={trackId}
                    reactions={submitter?.reactions}
                  />
                </motion.div>
              </RenderIfVisible>
            </div>
          ))}
      </div>
      <Screenshots
        key="screenshots-container"
        screenshotUrls={screenshotUrls}
        ipadScreenshotUrls={ipadScreenshotUrls}
      />
      {relatedApps && relatedApps.length > 0 && (
        <RenderIfVisible visibleOffset={-25}>
          <AppsList
            title={t.previewApp.relatedApps}
            apps={relatedApps
              ?.filter((item) => item.trackId !== trackId)
              .sort((a, b) => b.score! - a.score!)}
          />
          <div className="d-flex">
            <Link href={"/apps"} className="btn btn-primary rounded-sm m-auto">
              {t.home.viewAll}
            </Link>
          </div>
        </RenderIfVisible>
      )}
      <SubmitterCardModal
        disableDragUp={false}
        key="submitter-card-modal"
        modalOpen={!!selectedSubmitterCard}
        close={() => setSubmitterCardId(undefined)}
      >
        <FullSubmitterCard
          key={`full-submitter-card-${selectedSubmitterCard?.uid}`}
          trackId={app?.trackId}
          uid={selectedSubmitterCard?.uid}
          displayName={selectedSubmitterCard?.displayName}
          photoURL={selectedSubmitterCard?.photoURL}
          lastModified={selectedSubmitterCard?.lastModified}
          submitterDescription={selectedSubmitterCard?.submitterDescription}
          translatedDescription={selectedSubmitterCard?.translatedDescription}
          media={selectedSubmitterCard?.other?.tweet?.media}
          submittedApp={app}
          reactions={selectedSubmitterCard?.reactions}
        />
      </SubmitterCardModal>
      <Modal
        key="share-menu-modal"
        modalOpen={openShareMenu}
        close={toggleShareMenu}
      >
        <ShareMenu />
      </Modal>
    </AnimatePresence>
  )
}
export default StandaloneAppPreview
